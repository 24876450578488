import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { GlobalSet } from '../../global/GlobalProvider';
import { Button, Modal } from 'react-bootstrap';
import axios from "axios";
import swal from "sweetalert";
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

const BasicDatatable = () => {
  const { t } = useTranslation();
  const { CurrencySymbol } = GlobalSet();

 /*.....income data......*/
    const [income, setIncome] = useState([]);
    const fetchData = () => {
      const selectedCurrency = localStorage.getItem('selectedCurrency');
      const auth_token = localStorage.getItem('auth_token');
        const data = {
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
            currency: selectedCurrency,
        }
      if (auth_token !== null) {
         axios.post('/manage-promo-codes',data)
         .then(response=>{
           setIncome(response.data.data)
           console.log(response.data.data)
           
           })
           .catch(error=>{
             console.log(error)
         })
      }
  };
  useEffect(() => {
    fetchData();
  }, []);


  const [showForm, setShowForm] = useState(false);

  const handleShowButtonClick = () => {
    setShowForm(true);
  };

  const handleHideButtonClick = () => {
    setShowForm(false);
  };


    const [depositInput, setDepositInput] = useState({
        promocode: '',
        codename: '',
        error_list: [],
    });

    const handleInput = (e) => {
        e.persist();
        setDepositInput({...depositInput, [e.target.name]: e.target.value });
    }

    const promocodeSubmit = (e) => {
        e.preventDefault();
        const auth_token = localStorage.getItem('auth_token');
        const data = {
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
            promocode: depositInput.promocode,
            codename: depositInput.codename,
           
        }

      if (auth_token !== null) {
          axios.post(`/create-promo-code`, data).then(res => {
                if(res.data.status === 'success')
                {
                   swal("Success",res.data.message,"success");
                   fetchData();

                  setDepositInput({
                    ...depositInput,
                    promocode: '',
                    codename: '',
                    error_list: []
                  });  

                }
                else if(res.data.status === 'fail')
                {
                  swal("Warning",res.data.message,"warning");
                }
                else 
                {
                setDepositInput({...depositInput, error_list: res.data.validation_error});
                }
            });
        }
      }




  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Promo Codes</h4>
          {!showForm ? (
              <button onClick={handleShowButtonClick} type="button" class="me-2 btn btn-warning  btn-rounded"><span class="btn-icon-start text-info"><i class="fa fa-plus color-info"></i></span>Add</button>

          ) : (
              <button onClick={handleHideButtonClick} type="button" class="me-2 btn btn-warning  btn-rounded"><span class="btn-icon-start text-info"><i class="fa fa-plus color-info"></i></span>Add</button>

          )}

        </div>
        <div className="card-body">

          {!showForm ? (
           ""
          ) : (
            <div>
              <div className="col-xl-12 col-lg-12 mb-3">
                <div className="basic-form">
                 <form onSubmit={promocodeSubmit} className="needs-validation" noValidate="">
                    <div className="row">
                      <div className="col-sm-5">
                        <input
                           type="text"
                           className="form-control"
                           placeholder="Enter Promo Code"
                           id="promocode"
                           required
                           name="promocode"
                           onChange={handleInput} 
                           value={depositInput.promocode}                          
                        />
                        <span className="text-danger">{depositInput.error_list.promocode}</span>
                      </div>
                      <div className="col-sm-5 mt-2 mt-sm-0">
                        <input
                           type="text"
                           className="form-control"
                           placeholder="Code Name"
                           id="codename"
                           required
                           name="codename"
                           onChange={handleInput} 
                           value={depositInput.codename}  
                        />
                        <span className="text-danger">{depositInput.error_list.codename}</span>
                      </div>
                      <div className="col-sm-2 mt-2 mt-sm-0">
                        <button type="submit" className="btn btn-warning  mb-2">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}


          <div className="table-responsive">
            <div id="job_data" className="dataTables_wrapper">
              <table
                className="display w-100 dataTable "
                id="example5"
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th className="sorting_asc" style={{ width: "177px" }}>
                      SL
                    </th>
                    <th className="sorting_asc" style={{ width: "177px" }}>
                      Promo Code
                    </th>
                    <th className="sorting" style={{ width: "278px" }}>
                     Total Click
                    </th>
                    <th className="sorting" style={{ width: "128px" }}>
                      Create Time
                    </th>
                    <th className="sorting" style={{ width: "128px" }}>
                      Details
                    </th>
                  </tr>
                </thead>

                <tbody>
                      {income.map((incomerow, index) => (
                        <tr key={incomerow.id} className="odd" role="row">
                          <td className="sorting_1">{index + 1}</td>
                          <td className="sorting_1">{incomerow.referral_code}</td>
                          <td className="sorting_1">{incomerow.total_click} Click</td>
                          <td className="sorting_1">{incomerow.create_time}</td>
                          <td className="sorting_1">
                              <Link key={incomerow.id} to={"view-promo-codes/"+incomerow.referral_code}>
                                <i style={{ fontSize: '20px' }} class=" fa fa-info-circle " aria-hidden="true"></i>
                             </Link>                              
                          </td> 

                        </tr>
                      ))}
                </tbody>
               
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicDatatable;
