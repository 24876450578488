import React, { useContext } from "react";

/// React router dom
import {  Switch, Route } from "react-router-dom";


/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";
/// Dashboard
import Home from "./components/Dashboard/Home";

import Setting from "./layouts/Setting";

/////Demo
import Theme1 from "./components/Dashboard/Demo/Theme1";
import Theme2 from "./components/Dashboard/Demo/Theme2";


/// pages
import viewpage from "./components/AppsMenu/public/viewpage";

/// App
import Security from "./components/AppsMenu/AppProfile/Security";
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import IncomeHistory from "./components/AppsMenu/AppProfile/IncomeHistory";
import TransactionHistory from "./components/AppsMenu/AppProfile/TransactionHistory";
import ManagePromoCodes from "./components/AppsMenu/AppProfile/ManagePromoCodes";
import Affiliate from "./components/AppsMenu/AppProfile/Affiliate";
import MyBetHistory from "./components/AppsMenu/AppProfile/MyBetHistory";
import Withdraw from "./components/AppsMenu/AppProfile/Withdraw";
import ChangePassword from "./components/AppsMenu/AppProfile/ChangePassword";
import IdentityVerify from "./components/AppsMenu/AppProfile/IdentityVerify";
import SupportTicket from "./components/AppsMenu/AppProfile/SupportTicket";
import CreateTicket from "./components/AppsMenu/AppProfile/CreateTicket";
import ViewTicket from "./components/AppsMenu/AppProfile/ViewTicket";

import { ThemeContext } from "../context/ThemeContext";

const Markup = () => {
  const auth_token = localStorage.getItem('auth_token');
  const { menuToggle } = useContext(ThemeContext);



  const routes = [
  /// Apps
    { url: "dashboard", component:  Home},
    { url: "view-page/:id/:titel", component: viewpage }, 
    { url: "security", component: Security },
    { url: "app-profile", component: AppProfile },
    { url: "income-history", component: IncomeHistory },
    { url: "transaction-history", component: TransactionHistory},
    { url: "manage-promo-codes", component: ManagePromoCodes},
    { url: "view-promo-codes/:id", component: Affiliate},
    { url: "bet-history", component: MyBetHistory },
    { url: "withdraw", component: Withdraw },
    { url: "change-password", component: ChangePassword },
    { url: "identity-verification", component: IdentityVerify },
    { url: "support-ticket", component: SupportTicket },
    { url: "create-ticket", component: CreateTicket },
    { url: "view-ticket/:id", component: ViewTicket },
  ];


  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <Setting />
    <ScrollToTop />
    </>
  );
};

export default Markup;
