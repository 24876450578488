import React, { Fragment,useState,useEffect } from "react";
import { Form } from "react-bootstrap";
import PageTitle from "../../../layouts/PageTitle";
import axios from "axios";
import swal from "sweetalert";
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

const Checkout = () => {
   const { t } = useTranslation();
   const [withdraw, setWithdraw] = useState([]);

    useEffect(()=>{
        const data = {
              key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
             }
        axios.post('/apps-withdraw',data)
        .then(response=>{
           setWithdraw(response.data.data)
           console.log(response.data.data)
           
       })
     .catch(error=>{
            
           console.log(error)
       })
     },[])


    const [depositInput, setDepositInput] = useState({
        gateway_id: '',
        amount: '',
        transaction_number: '',
        password: '',
        error_list: [],
    });

    const handleInput = (e) => {
        e.persist();
        setDepositInput({...depositInput, [e.target.name]: e.target.value });
    }

    const depositSubmit = (e) => {
        e.preventDefault();
        const auth_token = localStorage.getItem('auth_token');
        const data = {
            gateway_id: depositInput.gateway_id,
            amount: depositInput.amount,
            transaction_number: depositInput.transaction_number,
            password: depositInput.password,
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
           
        }

      if (auth_token !== null) {
          axios.post(`/apps-withdraw-sub`, data).then(res => {
                if(res.data.status === 'success')
                {
                   swal("Success",res.data.message,"success");
                  setDepositInput({...depositInput,
                    gateway_id: '',
                    amount: '',
                    transaction_number: '',
                    password: '',
                    
                  });
                }
                else if(res.data.status === 'fail')
                {
                  swal("Warning",res.data.message,"warning");
                }
                else 
                {
                setDepositInput({...depositInput, error_list: res.data.validation_error});
                }
            });
          }

          }



   return (
      <Fragment>
         <div className="row">
            <div className="col-xl-12">
               <div className="card">
                  <div className="card-body">
                     <div className="row">
                        <div className="col-md-12 order-md-1">
                           <h4 className="mb-3">{t('pro_with_withdraw')}</h4>
                           <form onSubmit={depositSubmit} className="needs-validation" noValidate="">
                              <div className="row">

                  

                                 <div className="col-md-12 mb-3">
                                    <label>{t('pro_with_w_me')}</label>
                                    <select name="gateway_id" 
                                       onChange={handleInput} 
                                       required
                                       value={depositInput.gateway_id}
                                       className="form-control">
                                           <option value="">{t('pro_with_sel_get')}</option>
                                           {withdraw.map((ele)=>(
                                           <option value={ele.id} key={ele.id}>{ele.payment_method_name}</option>
                                           ))}
                                    </select>
                                    <span className="text-danger">{depositInput.error_list.payment_method_name}</span>
                                 </div>



                                 <div className="col-md-12 mb-3">
                                    <label htmlFor="lastName">{t('pro_with_amount')}</label>
                                    <input
                                       type="text"
                                       className="form-control"
                                       id="lastName"
                                       placeholder=""
                                       required
                                       name="amount"
                                       onChange={handleInput} 
                                       value={depositInput.amount}
                                    />
                                       <span className="text-danger">{depositInput.error_list.amount}</span>
                                 </div>



                                 <div className="col-md-12 mb-3">
                                    <label htmlFor="firstName">
                                       {t('pro_with_tr_number')}
                                    </label>
                                    <input
                                       type="text"
                                       className="form-control"
                                       id="firstName"
                                       placeholder=""
                                       required
                                       name="transaction_number"
                                       onChange={handleInput} 
                                       value={depositInput.transaction_number}
                                    />
                                    <span className="text-danger">{depositInput.error_list.transaction_number}</span>
                                    
                                 </div>

                                 <div className="col-md-12 mb-3">
                                    <label htmlFor="firstName">
                                        {t('pro_with_password')}
                                    </label>
                                    <input
                                       type="password"
                                       className="form-control"
                                       id="firstName"
                                       placeholder=""
                                       required
                                       name="password"
                                       onChange={handleInput} 
                                       value={depositInput.password}
                                    />
                                   <span className="text-danger">{depositInput.error_list.password}</span>
                                    
                                 </div>
                              </div>

                              <hr className="mb-4" />
                              <button
                                 className="btn btn-primary btn-lg btn-block"
                                 type="submit"
                              >
                                 {t('pro_with_withdraw')}
                              </button>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export default Checkout;
