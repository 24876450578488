import { lazy, Suspense, useEffect, useState } from 'react';

/// Components
import Index from "./jsx";
import { connect, useDispatch } from 'react-redux';
import {  Route, Switch, withRouter ,useHistory, useLocation} from 'react-router-dom';
// action
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import axios from "axios";
import swal from "sweetalert";
import { GlobalProvider } from './jsx/components/global/GlobalProvider';
import AuthGlobal from './jsx/components/global/AuthGlobal'; 

axios.interceptors.request.use(function (config){
  const token = localStorage.getItem('auth_token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

const Home = lazy(() => import('./jsx/frontend/home'));
const SignUp = lazy(() => import('./jsx/pages/Registration'));
const Login = lazy(() => import('./jsx/pages/Login'));
const ForgotPassword = lazy(() => import('./jsx/components/AppsMenu/public/ForgotPassword'));
const ForgotChangePassword = lazy(() => import('./jsx/components/AppsMenu/public/ForgotChangePassword'));
function App (props) {

// Online state
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  useEffect(() => {
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };
    window.addEventListener('online', handleStatusChange);
    window.addEventListener('offline', handleStatusChange);
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [isOnline]);

  if(!isOnline){
       swal("You Are Offline !!","Check Your Internet Connection");
  }

  let routes = (  
      <Switch>
          <Route path='/login' component={Login} />
          <Route path='/register' component={SignUp} />
          <Route path='/forgot-password' component={ForgotPassword} />
          <Route path='/forgot-change-password' component={ForgotChangePassword} />
          <Route path='/' component={Home} />
      </Switch>
  );  
    
  const auth_token = localStorage.getItem('auth_token');
  const history = useHistory();
  const path = window.location.pathname;
  useEffect(() => {
    if (auth_token) {

            const data = {
                key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
            }
            if (auth_token !== null) {

                axios.post(`/checkinAuthenticated`, data).then(res => {
                    if(res.data.status === 'success')
                    {
                      if (path === "/login" || path === "/register" || path === "/forgot-password") {
                        history.push('/dashboard');
                      }
                    }
                    else if(res.data.status === 'fail')
                    {
                      localStorage.removeItem('auth_token');
                      swal("Please login Again",res.data.message,"warning");
                     history.push('/login');
                    }
                   else 
                    {
                      localStorage.removeItem('auth_token');
                      swal("Please login Again","","error");
                     history.push('/login');
                    }
                });
              }
              else 
              {
               localStorage.removeItem('auth_token');
                swal("Please Login Again","","error");
                     history.push('/login');
              }

    } else {   
      if (path === "/login" || path === "/register" || path === "/forgot-password" || path === "/forgot-change-password") {
       history.push(path+'?'+urlSearchParams);
      }else if(auth_token == null){
        history.push(path+'?'+urlSearchParams);
      }else{
       history.push(path+'?'+urlSearchParams);
      }    
    }
  }, [history]);

if(auth_token){
   if (path === "/" || path === "/home") {
    return (
      <div className="vh-100">
          <Suspense fallback={
              <div id="preloader">
                  <div className="sk-three-bounce">
                      <div className="sk-child sk-bounce1"></div>
                      <div className="sk-child sk-bounce2"></div>
                      <div className="sk-child sk-bounce3"></div>
                  </div>
              </div>
            }
          >
          {routes}
          </Suspense>
      </div>
    );
  }else{
    return (
      <>
        <GlobalProvider>
             <Suspense fallback={
                <div id="preloader">
                </div>  
               }>
                <Index / >
            </Suspense>
        </GlobalProvider>
      </>
    );
  }

}else{
    return (
      <div className="vh-100">
          <Suspense fallback={
              <div id="preloader">
                  <div className="sk-three-bounce">
                      <div className="sk-child sk-bounce1"></div>
                      <div className="sk-child sk-bounce2"></div>
                      <div className="sk-child sk-bounce3"></div>
                  </div>
              </div>
            }
          >
          {routes}
          </Suspense>
      </div>
    );
}

};
export default withRouter(App); 

